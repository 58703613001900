import React from 'react'
import styled from 'styled-components'
import {
  colors,
  VW,
  typography
} from '../../styles'

const Signatures = ({
  signatures,
  color,
  mobileFontSize
}) => {
  return (
    <StyledSignatures mobileFontSize={mobileFontSize}>
      <ul>
        {signatures.map((signature, i) => (
          <Signature
            key={`signature-${i}`}
            image={signature.image.fluid.src}
            color={color} >
            <p>{signature.name}</p>
          </Signature >
        ))}
      </ul>
    </StyledSignatures>
  )
}

const SIGNATURE_HEIGHT_RATIO = '5.5294117647'

const StyledSignatures = styled.div`
  ${typography.body.regular}
  ${props => props.mobileFontSize && `font-size: ${props.mobileFontSize}px;`}
  margin-top: calc(${SIGNATURE_HEIGHT_RATIO} * ${props => props.mobileFontSize || '17'}px);

  ul {
    font-size: 1em;
  }

  @media (min-width: ${VW.desktop}) {
    ${typography.body.regular}
    margin-top: calc(${SIGNATURE_HEIGHT_RATIO} * 17px);
  }

  @media (max-width: 440px) {
    font-size: 11px;

    margin-top: calc(${SIGNATURE_HEIGHT_RATIO} * 11px);
  }
`

const Signature = styled.li`
  color: ${props => colors[props.color]};
  position: relative;
  display: inline-block;
  margin-left: 18px;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: -1.1176470588em;
    height: 5.3529411765em;
    width: 12em;
    background-repeat: no-repeat;
    background-size: contain;
    // background-position: center;
    background-image: url("${(props) => props.image}");
  }

  li {
    font-size: 1em;
  }
  
  & + li {
    margin-left: 5.8823529412em;

    &::before {
      left: -2.7647058824em;
      height: 5.4705882353em;
    }
  }

  p {
    margin: 0;
  }
`

export default Signatures