import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Signatures from '../components/shared/Signatures'

import styled from 'styled-components'
import {
  VW,
  colors,
  typography,
  icons,
  VARIABLE_FUNCTION
} from '../styles'
import RichText from '../components/RichText'
import { LayoutContext } from '../contexts'

const Text = ({
  data: {
    contentfulContactPage: {
      seoTitle,
      seoDescription,
      additionalInformation: {
        json: additionalInformation
      },
      backgroundImage: {
        fluid: {
          src: backgroundImage
        }
      },
      emailTextLink: {
        json: emailTextLink
      },
      intro,
      signatures
    }
  },
}) => {
  const { headerBanner } = useContext(LayoutContext)

  return (
    <StyledContact_wrapper
      backgroundImage={backgroundImage}
      headerBanner={headerBanner} >
      <StyledContact_container>
        <SEO
          title={seoTitle}
          description={seoDescription}
          slug=' ' />
        <ContactCard_wrapper>
          {icons.redCircle}
          <ContactCard_container>
            <Intro>{intro}</Intro>
            <EmailTextLink>
              <RichText json={emailTextLink} />
            </EmailTextLink>
            <AdditionalInformation>
              <RichText json={additionalInformation} />
            </AdditionalInformation>
            <Signatures
              signatures={signatures}
              color='white'
              mobileFontSize='12' />
          </ContactCard_container>
        </ContactCard_wrapper>
      </StyledContact_container>
    </StyledContact_wrapper>
  )
}

const StyledContact_wrapper = styled.div`
  min-height: calc(100vh - ${props => VARIABLE_FUNCTION(props.headerBanner).mobileHeader});
  margin-top: ${props => VARIABLE_FUNCTION(props.headerBanner).mobileHeader};
  padding: 0 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('${props => props.backgroundImage}');
  overflow: hidden;

  &,
  * {
    box-sizing: border-box;
  }

  @media (min-width: ${VW.desktop}) {
    height: calc(100vh - ${props => VARIABLE_FUNCTION(props.headerBanner).desktopHeader});
    margin-top: ${props => VARIABLE_FUNCTION(props.headerBanner).desktopHeader};
  }
`
const StyledContact_container = styled.div`
  max-width: 1200px;
  margin: auto;
`

const TOP_RATIO = '0.1544799176'

const ContactCard_wrapper = styled.div`
  position: relative;
  padding-bottom: calc((100vh * ${TOP_RATIO}) * 2);

  & > svg {
    position: absolute;
    top: calc(100vh * ${TOP_RATIO} - 135px);
    left: -120px;
  }
`

const ContactCard_container = styled.div`
  position: relative;
  top: calc(100vh * ${TOP_RATIO});
  left: 0;
  background-color: ${colors.red};
  color: white;
  max-width: 353px;
  padding: 24px 30px;
  
  @media (min-width: ${VW.desktop}) {
    max-width: 450px;
    padding: 30px 40px;
  }
`

const Intro = styled.h1`
  ${typography.body.big}

  @media (min-width: ${VW.desktop}) {
    ${typography.headlines.regular}
  }
`

const EmailTextLink = styled.div`
  ${typography.body.big}
  margin-left: 60px;
  text-decoration: underline;

  p {
    margin-bottom: 0;
  }

  @media (min-width: ${VW.desktop}) {
    margin-left: 80px;
    ${typography.headlines.regular}
  }
`

const AdditionalInformation = styled.div`
  ${typography.body.regular}
  display: inline-block;
  margin: 80px 0 20px;

  p {
    margin-top: 0;
  }


`

export const PageQuery = graphql`
  query Contact($id: String!) {
    contentfulContactPage(id: { eq: $id }) {
      seoTitle
      seoDescription
      slug
      additionalInformation {
        json
      }
      backgroundImage {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      emailTextLink {
        json
      }
      intro
      signatures {
        name
        image {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default Text
